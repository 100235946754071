import { useTranslation } from "react-i18next";
import "./index.css";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer id="contacts">
      <div className="background-footer">
        <img src="../../../assets/images/footer.png" />
        <div className="contact-wrapper">
          <div className="contact-item">
            <p>{t("adress")}</p>
            <span>{t("addressText")}</span>
          </div>
          <div className="contact-item">
            <p>{t("Contacts")}</p>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "4px" }}
            >
              <span>+7 (999) 995–98–54</span>
              <span>+373 (78) 454–685</span>
              <span>hello@todaydigital.pro</span>
              <a href="https://t.me//todaydigital_pro" target="_blank">
                Telegram
              </a>
            </div>
          </div>
          <div className="contact-item">
            <p>{t("Socials")}</p>
            <div className="social-items">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <a href="https://t.me/todaydigitalpro" target="_blank">
                  {t("telegram-kanal")}
                </a>
                <a
                  href="https://www.instagram.com/today_digital2025/"
                  target="_blank"
                >
                  Instagram
                </a>
                <a
                  href="https://www.facebook.com/todaydigitalpro"
                  target="_blank"
                >
                  Facebook
                </a>
              </div>

              <div style={{ display: "flex", flexDirection: "column" }}>
                <a href="https://dprofile.ru/todaydigital" target="_blank">
                  Dprofile
                </a>
                <a href="https://www.behance.net/todaydigital1" target="_blank">
                  Behance
                </a>
                <a
                  href="https://vc.ru/u/2574274-today-digital"
                  target="_blank"
                  style={{ marginRight: "15px" }}
                >
                  VC
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span className="copyright">
        © S.R.L. TODAY DIGITAL. All rights reserved. 2024
      </span>
    </footer>
  );
};
export default Footer;
